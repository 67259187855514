const AppVersion = '1.0.0';
const AppTokenKey = 'token';
const AppuserType = 'userType';
const AppKeyPermissions = 'ALL'


export {
  AppVersion,
  AppTokenKey,
  AppuserType,
  AppKeyPermissions
}
