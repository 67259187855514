import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './App.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Page403 = React.lazy(() => import('./views/pages/page403/Page403'))
const CompanyForm = React.lazy(() => import('./views/pages/company/CompanyForm'))
const EventForm = React.lazy(() => import('./views/pages/admin/eventUser/EventForm'))
import PrivateRoute from './config/PrivateRoute';
class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route exact path="/CompanyForm" name="Company Form" render={(props) => <CompanyForm {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route exact path="/403" name="Page 403" render={(props) => <Page403 {...props} />} />
            
            <PrivateRoute path="/admin" name="Home" component={DefaultLayout}/>
            <Route component={Page404}/>
          </Switch>
        </React.Suspense>
      </HashRouter>
    )
  }
}

export default App
