import { AppTokenKey, AppKeyPermissions, AppuserType } from './config/constants';

const storeLogonData = ({ appToken, appPermissions, user }) => {
  return Promise.resolve().then(() => {
    localStorage.setItem(AppTokenKey, appToken);
    localStorage.setItem(AppKeyPermissions, appPermissions);
    localStorage.setItem('name', user.name);
    localStorage.setItem('id', user.id);
    localStorage.setItem(AppuserType, user.userType);
  });
}

const authenticated = () => {
  const appToken = localStorage.getItem(AppTokenKey);
  const appPermissions = localStorage.getItem(AppKeyPermissions);
  const userType = localStorage.getItem(AppuserType);
  if (appToken && appPermissions && (userType==0 || userType==2)) {
    return true;
  }

  return false;
}


const authenticatedAdmin = () => {
  const appToken = localStorage.getItem(AppTokenKey);
  const appPermissions = localStorage.getItem(AppKeyPermissions);
  const userType = localStorage.getItem(AppuserType);
  if (appToken && appPermissions && userType==0) {
    return true;
  }

  return false;
}


const authenticatedCompany = () => {
  const appToken = localStorage.getItem(AppTokenKey);
  const appPermissions = localStorage.getItem(AppKeyPermissions);
  const userType = localStorage.getItem(AppuserType);
  if (appToken && appPermissions && userType==2) {
    return true;
  }

  return false;
}




const logout = () => {
  localStorage.clear();
}

const tokenJWT = () => {
  return {
    appToken: localStorage.getItem(AppTokenKey),
    appPermissions: localStorage.getItem(AppKeyPermissions),
    name: localStorage.getItem('name'),
    id: localStorage.getItem('id'),
    userType: localStorage.getItem(AppuserType),

  }
}

export {
  storeLogonData,
  authenticated,
  authenticatedAdmin,
  authenticatedCompany,
  logout,
  tokenJWT
}
